import axios from "axios";
import { config } from "../../src/enviroment";
import moment from "moment";
import { routePaths } from "../components/config/route-paths";
import { history } from "../helpers/index";
import { store } from "../helpers/index";

const endPoints = config.urls;

export const usageService = {
  electricityUsageTrendGraph,
  accountDetais,
  paymentReceipt,
  billDetails,
  prePaymentProcessing,
  downloadData,
  meterConsumtion,
  verifyPaymentHash,
  prePaymentProcessingBillPay,
};
async function electricityUsageTrendGraph(accountId, dashboardUsage, year) {
  let endDate;
  let startDate;
  let endDateFormat;
  if (dashboardUsage === "dashboardUsage") {
    endDate = new Date();
    startDate = moment(endDate)
      .subtract(6, "months")
      .format("YYYY-MM-DD");
    endDateFormat = moment(endDate).format("YYYY-MM-DD");
  } else {
    let start = moment(year).startOf("year");
    endDate = moment(year).endOf("year");
    startDate = moment(start).format("YYYY-MM-DD");
    endDateFormat = moment(endDate).format("YYYY-MM-DD");
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query:
            `{ getUsageTrends(input:{startDate:"` +
            startDate +
            `",endDate:"` +
            endDateFormat +
            `"})
          { usageQuantity startdate
          endDate usageUnit connectionId intervalName intervalShortName} }
       `,
        },
        {
          headers: {
            accountId: accountId,
          },
        }
      )
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
async function meterConsumtion(apiInput, accountId) {
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let startDate = moment(apiInput.year).startOf("year");
  let endDate = moment(apiInput.year).endOf("year");
  let startFormat = moment(startDate).format("YYYY-MM-DD");
  let endFormat = moment(endDate).format("YYYY-MM-DD");
  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query:
            `{
            getMeterReads(input:{startDate:"` +
            startFormat +
            `",
              endDate:"` +
            endFormat +
            `"})
            { usageQuantity startdate endDate usageUnit connectionId} } 
       `,
        },
        {
          headers: {
            accountId: accountId,
            tenantCode: tenantCode,
          },
        }
      )
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
function accountDetais() {
  return axios.get(`${endPoints.USAGE_SERVICE_ENDPOINT}account-details`);
}

async function paymentReceipt(accountId, paymentId) {
  let tenantCode = config.constants.BASE_TENANT_CODE;

  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query: `{getPaymentReceipt{accountNumber,paymentEventId,paymentDate,paymentStatus,cardType,cardNumber,premise,amountPaid,amountPaidInWords,matchIdType,matchIdValue,payId,payType,payMode}}
       `,
        },
        {
          headers: {
            accountId: accountId,
            paymentEventId: paymentId,
            tenantCode: tenantCode,
          },
        }
      )
      .then(function(response) {
        resolve(response);
        if (response.status === 0) {
          let getRetryPage = localStorage.getItem("getRetryPage");
          if (getRetryPage && getRetryPage == "true") {
            history.push(routePaths.RETRY);
          }
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function billDetails(accountId, billId) {
  let billHeader = "";
  if (billId) {
    billHeader = {
      tenantCode: config.constants.BASE_TENANT_CODE,
      accountId: accountId,
      billId: billId,
    };
  } else {
    billHeader = {
      tenantCode: config.constants.BASE_TENANT_CODE,
      accountId: accountId,
    };
  }
  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query: `{getBillDetails{   
                   accountDetails {     
                         name, 
                         email,
                         phone, 
                         address,
                         accountId, 
                         currency
                        },       billDetails {     
                           billMessages, billRouting, ,billNumber,        total,        billDueDate,        billDate,       totalAmountDue,        billRoute,  totalCorrections, totalAdjustments,billPaymentStatus   }      
                            summaryOfCharges{  billSegmentSequenceNumber,     headerSequenceNumber   saId,          saInfo, premiseInfo,endDate,startDate,rateDesc,billSegmentPeriod,billSegmentInfo,          name,           value{        sequenceNumber,    name,           value         }     
        }
        }}
       `,
        },
        {
          headers: billHeader,
        }
      )
      .then(function(response) {
        resolve(response);
        if (response.status === 0) {
          let getRetryPage = localStorage.getItem("getRetryPage");
          if (getRetryPage && getRetryPage == "true") {
            history.push(routePaths.RETRY);
          }
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function prePaymentProcessing(connectionId, totalSelAmount, paymentType) {
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let bearer = localStorage.getItem("bearer");
  let emailId = "";
  let userName = "";
  let sessionId = "";
  let globalStore = null;
  let firstName = "";
  let lastName = "";
  if (globalStore === null) {
    globalStore = store.getState();
  }
  if (globalStore) {
    firstName = globalStore.accountInformation.demographicDetails.firstName;
    lastName = globalStore.accountInformation.demographicDetails.lastName;
  }
  if (bearer) {
    bearer = JSON.parse(bearer);
    emailId = bearer?.email;
    userName = bearer?.userName;
    sessionId = bearer?.sessionId;
  }
  let details = localStorage
    .getItem("PaymentConnections")
    .replace(/"([^"]+)":/g, "$1:");
  let APIquery =
    `query {
    prePaymentProcessing(
      input: {
        RequestInput:{
        Amount: ` +
    totalSelAmount +
    `
        PaymentType:"` +
    paymentType +
    `"
        tenantCode: "` +
    tenantCode +
    `"
        Quantity: 1
        ConnectionId: "` +
    connectionId +
    `"  
    userName:"` +
    userName +
    `"
    primaryEmail:"` +
    emailId +
    `"     
    userSessionId:"` +
    sessionId +
    `"
        firstName: "` +
    firstName +
    `"
      lastName: "` +
    lastName +
    `"
      Connections: 
          ` +
    details +
    ` 
      }
    }
    ) 
  }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function downloadData(accountId, code, billId, paymentId, startDate) {
  let downloadHeader = "";
  let primaryKeyType = "";
  let tenantCode = config.constants.BASE_TENANT_CODE;
  if (billId) {
    downloadHeader = {
      tenantCode: tenantCode,
      accountId: accountId,
      billId: billId,
    };
    primaryKeyType = "billId";
  } else if (paymentId) {
    downloadHeader = {
      tenantCode: tenantCode,
      accountId: accountId,
      paymentEventId: paymentId,
    };
    primaryKeyType = "paymentEventId";
  } else {
    downloadHeader = {
      tenantCode: tenantCode,
      accountId: accountId,
    };
    primaryKeyType = "accountId";
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query: `{ getGeneratedDocument(input:{
            serviceName:"${code}",
            primaryKeyType:"${primaryKeyType}"
            ${
              code === "STATEMENT_OF_ACCOUNTS_DOCUMENT"
                ? `,startDate:"${startDate}"`
                : ""
            }})}`,
        },
        {
          headers: downloadHeader,
        }
      )
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function verifyPaymentHash(
  status,
  statusMessage,
  confirmationId,
  merchantId,
  ezConnectResponseDate,
  amount,
  currencyCode,
  merchantSessionId,
  pun,
  bankId,
  cardExpiryDate,
  cardHolderName,
  cardNumber,
  secureHash
) {
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.USAGE_SERVICE_BASE_URL, {
        query:
          `{
            verifyPaymentHash(input:{Status:"` +
          status +
          `",StatusMessage:"` +
          statusMessage +
          `",
            ConfirmationId:"` +
          confirmationId +
          `", MerchantId:"` +
          merchantId +
          `",
            EZConnectResponseDate:"` +
          ezConnectResponseDate +
          `",Amount:` +
          amount +
          `,
            CurrencyCode:` +
          currencyCode +
          `,MerchantModuleSessionId:"` +
          merchantSessionId +
          `",
            PUN:"` +
          pun +
          `",BankID:"` +
          bankId +
          `",CardExpiryDate:"` +
          cardExpiryDate +
          `",CardHolderName:"` +
          cardHolderName +
          `",
            CardNumber:"` +
          cardNumber +
          `", SecureHash:"` +
          secureHash +
          `"}){
              Status,StatusMessage,ConfirmationId,MerchantId,EZConnectResponseDate,Amount
              ,CurrencyCode,MerchantModuleSessionId,PUN,BankID,CardExpiryDate,CardHolderName,
              CardNumber
            }
          }
   `,
      })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function prePaymentProcessingBillPay(
  billId,
  totalSelAmount,
  creditCardGatewayCode,
  connectionId
) {
  let tenantCode = config.constants.BASE_TENANT_CODE;

  let bearer = localStorage.getItem("bearer");
  let emailId = "";
  let userName = "";
  let sessionId = "";
  if (bearer) {
    bearer = JSON.parse(bearer);
    emailId = bearer?.email;
    userName = bearer?.userName;
    sessionId = bearer?.sessionId;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.PREPAYMENTBILL, {
        RequestInput: {
          Amount: totalSelAmount,
          PaymentType: creditCardGatewayCode,
          tenantCode: tenantCode,
          source: "UNPAID_PAY",
          billId: billId,
          ConnectionId: connectionId,
          userName: userName,
          primaryEmail: emailId,
          userSessionId: sessionId,
        },
      })

      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
